import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Ceomodel() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary"  id="bttcolor" onClick={handleShow}>
        Read More
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        <Modal.Title >Message from  <span className='modeltitext'>CEO </span>  </Modal.Title> 
        </Modal.Header>
        <Modal.Body>
          <p>           Diagnostics has a vital role to play in the wellness and early recovery of the patient from diseases., be it curative,
                 palliative or preventive. Better and efficient systems are quickly evolving around the ever-increasing demand for quality and affordable testing.</p>
                  
                  
                   <p>    Diagnostic have a conclusive impact on both early detection of ailments and the care continuum and greatly helps in determination of treatment regime and am glad the industry is innovating like never before. We can see newer trends emerging like the comprehensive care centres for such patients needing out-of-hospital care for the rehabilitative or recuperative care, Managed Home Care, increase in domiciliary care centres and spurt in home collections and all these are welcome changes that are going to redefine the industry and healthcare in coming times.
 
            </p>
            <p> 
            Pari passu, our industry should also show commitment towards climate change. There is a need for using re-usable and recyclable, environmentally friendly products. The instruments should also use latest available technologies so as to remain compatible and connected with the system network for easy data transfers and storage as it helps in saving unnecessary printouts.

           </p>
           <p>       
           May we succeed in our endeavours!


              </p>
         
       
           
            
         

        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default Ceomodel