function Corevalue() {
  return (
    <>
   
         <section
        className="dfine-feature-area"
        id="vision"
        style={{
          background: `linear-gradient(90deg, rgba(80,55,163,1) 0%, rgba(150,65,217,1) 0%, rgba(90,22,143,1) 100%, rgba(118,125,126,1) 100%, rgba(102,106,107,1) 100%, rgba(145,76,228,1) 100%, rgba(147,72,224,1) 100%, rgba(16,16,17,1) 100%, rgba(49,52,52,0.9472163865546218) 100%, rgba(120,37,185,1) 100%, rgba(66,67,69,1) 100%, rgba(90,90,93,1) 100%, rgba(117,119,120,1) 100%`,
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <h2 className="corehead text-white text-center mb-4">Core Values:</h2>
           
            <div className="col-lg-4 mb-3">
              <div id="coll1">
                <h6 className="coretitle mb-3 text-white">CONTINUOUS LEARNING:</h6>

                <p className="coretext">
                 
                Adapt with the changing technologies through continuous learning


                </p>
              </div>
            </div>

            <div className="col-lg-4 mb-3">
              <div id="coll1">
                <h6 className="coretitle mb-3 text-white">INNOVATE:</h6>

                <p className="coretext">
                Continuously Innovate so as to stay relevant.

                </p>
              </div>
            </div>

            <div className="col-lg-4 mb-3">
              <div id="coll1">
                <h6 className="coretitle mb-3 text-white">CONTRIBUTE:</h6>

                <p className="coretext">
                To the welfare of employees, society at large and environmental safety
                </p>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-4 mb-3">
              <div id="coll1">
                <h6 className="coretitle mb-3 text-white">RESPECT:</h6>

                <p className="coretext">
                Harness mutual respect in our day to day interactions with all

                </p>
              </div>
            </div>

            <div className="col-lg-4 mb-3">
              <div id="coll1">
                <h6 className="coretitle mb-3 text-white">HONESTY AND INTEGRITY:</h6>

                <p className="coretext">
                Maintain discipline, adopt transparency in pricing and inculcate high ethics and integrity in business transactions.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </section>
    </>
  );
}

export default Corevalue;
