import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Teammodel() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" id="btcolor" onClick={handleShow}>
        Read More
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title >About <span className='modeltitext'>Divya Acharya</span> </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='mx-4'>Divya Acharya is an computer science Graduate, Bangalore and has 12+ years of experience in the domains of  HR and Finance, Administration and Management. </p>

          <p className='mx-4'>  Has a fair knowledge of Medical Diagnostics industry and currently in the new role as CMD, trying to understand the nitty gritty of R&D and Manufacturing at DBI.
          </p>






        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default Teammodel