import NewsEventsSlider from './NewsEventsSlider';
import ModalDialog from "./ModalDialog";
import Even from "../images/news_img1.png";


function Event() {
  return (
    <>
      <section
        className="dfine-feature-area"
        style={{
          background: `linear-gradient(90deg, rgba(68,68,70,1) 0%, rgba(16,16,17,1) 35%, rgba(86,90,91,0.9472163865546218) 67%, rgba(118,125,126,1) 94%, rgba(102,106,107,1) 97%, rgba(117,119,120,1)`,
          backgroundSize: "cover",
          overflow: "hidden",
        }}
        id="Event"
      >
        <div className="row justify-content-center">
          <h2 className="Evenhead text-white">News &amp; Events</h2>
        </div>

        {/* <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="">
                <p className="Epare text-center">
                  In publishing and graphic design, Lorem ipsum is a placeholder
                  text commonly used to demonstrate the visual form
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center mt-5 justify-content-center">
            <div className="col-lg-5">
              <div className="section-title section-title-2 ">
                <img src={Even} alt="telegrap" style={{ width: '100%' }} />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="section-title mb-5 ">
                <p className="Epare  mx-4">
                  We are most relevant to IVD industry and with our deep roots
                  in this industry we remain focussed on developing solutions
                  for Entry to Mid-Size Segment Clinical Labs across all tiers.
                </p>
              </div>
              <ModalDialog />
            </div>
          </div>

        </div> */}
<NewsEventsSlider  />
      </section>
      
    </>
  );
}

export default Event;
