import React, { Component } from "react";
import Slider from "react-slick";
import teem from "../images/teem.jpg";
import Team from "../images/ms1.png";
import Team1 from "../images/ms2.png";
import Team2 from "../images/ms3.png";
import Team3 from "../images/ms4.png";
import Team4 from "../images/ms5.png";
import Team5 from "../images/ms6.png";
import Team6 from "../images/ms7.png";
import lind from "../images/linkden.png";
import User from "../images/user.png";
import Teammodel from "./Teammodel";
import Teammmodel from "./Teammmodel";
import Lokeshmodel from "./Lokeshmodel";
import Prathapmodel from "./Prathapmodel";
import Manasamodel from "./Manasamodel";
import Saimodel from "./Saimodel";
import Shuchimodel from "./Shuchimodel";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "" }}
      onClick={onClick}
    />
  );
}

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <section
        className="dfine-feature-area ceo_section"
        style={{
          backgroundImage: `url(${teem})`,
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
        {/* <section
        className="dfine-feature-area"
        id="vision"
        style={{
          background: `linear-gradient(90deg, rgba(80,55,163,1) 0%, rgba(55,75,172,1) 100%, rgba(117,119,120,1) 100%, rgba(21,39,230,1) 100%, rgba(0,0,0,1) 100%, rgba(118,125,126,1) 100%, rgba(102,106,107,1) 100%, rgba(145,76,228,1) 100%, rgba(31,30,32,1) 100%, rgba(16,16,17,1) 100%, rgba(49,52,52,0.9472163865546218) 100%, rgba(120,37,185,1) 100%, rgba(66,67,69,1) 100%, rgba(90,90,93,1) 100%) 100%`,
          backgroundSize: "cover",
          overflow: "hidden",
          paddingTop: "47px",
        }}
      > */}
        <div className="container">
          <div className="">
            <br />
            <h2 className="teamhead mb-3 text-white text-center"> Core Team </h2>
            <Slider {...settings}>
            <div>
                <figure className="snip1192">
                  <div className="author">
                    <img src={Team} alt="team" />

                    <blockquote>
                      <div className="namespace mt-3">
                        <span className="teamspace"></span>
                        <h6 className="teamspace">Divya Acharya</h6>

                        <p className="teamparar">
                          Founder and CMD
                          <br/>
                          <span>
                            {" "}
                            <a
                              href="https://www.linkedin.com/in/iamdiva"
                              target="_blank"
                            >
                              {" "}
                              <i class="fa fa-linkedin-square" id="fafa">
                                {" "}
                              </i>
                            </a>
                          </span>
                        </p>
                        <Teammodel />
                      </div>
                    </blockquote>
                  </div>
                </figure>
              </div>
              <div>
                <figure className="snip1192">
                  <div className="author">
                    <img src={Team1} alt="team1" />

                    <blockquote>
                    <div className="namespace mt-3">
                      <h6 className="teamspace">Afser Shariff </h6>
                     
                      <p className="teamparar">Co-Founder and CEO
                      <br/>
                      <span>
                            {" "}
                            <a
                              href="https://www.linkedin.com/in/hiofficer"
                              target="_blank"
                            >
                              {" "}
                              <i class="fa fa-linkedin-square" id="fafa">
                                {" "}
                              </i>
                            </a>
                          </span>
                        </p>
                     


                      <Teammmodel />
                      </div>
                    </blockquote>
                  </div>
                </figure>
              </div>
         

              <div>
                <figure className="snip1192">
                  <div className="author">
                    <img src={Team3} alt="team2" />

                    <blockquote>
                    <div className="namespace mt-3">
                      <h6 className="teamspace">Lokesh Murthy K M</h6>
                      <p className="teamparar">
                        Co-Founder and Technical Director
                       <br/>
                          <span>
                            {" "}
                            <a
                              href="https://www.linkedin.com/in/lokesh-murthy-368bab23"
                              target="_blank"
                            >
                              {" "}
                              <i class="fa fa-linkedin-square" id="fafa">
                                {" "}
                              </i>
                            </a>
                          </span>
                          </p>

                      <Lokeshmodel />
                      </div>
                    </blockquote>
                  </div>
                </figure>
              </div>
              <div>
                <figure className="snip1192">
                  <div className="author">
                    <img src={Team2} alt="team1" />

                    <blockquote>
                    <div className="namespace mt-3">
                      <h6 className="teamspace">Prathap</h6>
                      <p className="teamparar">
                        Co-Founder and Marketing Director
                        <br/>
                        <span>
                            {" "}
                            <a
                              href="https://www.linkedin.com/in/prathap-m-0428a9b0"
                              target="_blank"
                            >
                              {" "}
                              <i class="fa fa-linkedin-square" id="fafa">
                                {" "}
                              </i>
                            </a>
                          </span>
                          </p>
                     

                      <Prathapmodel />
                      </div>
                    </blockquote>
                  </div>
                </figure>
              </div>

              <div>
                <figure className="snip1192">
                  <div className="author">
                    <img src={Team4} alt="team2" />

                    <blockquote>
                    <div className="namespace mt-3">
                      <h6 className="teamspace">Manas Kumar Panda</h6>
                 
                      <p className="teamparar">Sr.Accounts Manager
                      <br/>
                        <span>
                            {" "}
                            <a
                              href="https://www.linkedin.com/in/manas-panda-05a63717"
                              target="_blank"
                            >
                              {" "}
                              <i class="fa fa-linkedin-square" id="fafa">
                                {" "}
                              </i>
                            </a>
                          </span>
                          </p>
                     

                      <Manasamodel />
                      </div>
                    </blockquote>
                  </div>
                </figure>
              </div>
              <div>
                <figure className="snip1192">
                  <div className="author">
                    <img src={Team6} alt="team2" />

                    <blockquote>
                     
                    <div className="namespace mt-3">
                      <h6 className="teamspace">Shuchi Kumari</h6>
                    
                      <p className="teamparar">QC and Regulatory Analyst
                      <br/>
                        <span>
                            {" "}
                            <a
                              href="https://www.linkedin.com/in/shuchi-kumari-9b7342220"
                              target="_blank"
                            >
                              {" "}
                              <i class="fa fa-linkedin-square" id="fafa">
                                {" "}
                              </i>
                            </a>
                          </span>
                          </p>

                      <Shuchimodel />
                      </div>
                    </blockquote>
                  </div>
                </figure>
              </div>
              <div>
                <figure className="snip1192">
                  <div className="author">
                    <img src={Team5} alt="team2" />

                    <blockquote>
                    
                    <div className="namespace mt-3">
                      <h6 className="teamspace">Medicherla Sai Prathima</h6>
                   
                      <p className="teamparar">Regulatory and IP Analyst
                      <br/>
                        <span>
                            {" "}
                            <a
                              href="https://www.linkedin.com/in/sai-prathima-m-049927221"
                              target="_blank"
                            >
                              {" "}
                              <i class="fa fa-linkedin-square" id="fafa">
                                {" "}
                              </i>
                            </a>
                          </span>
                          </p>

                      <Saimodel />
                      </div>
                    </blockquote>
                  </div>
                </figure>
              </div>
     
            </Slider>
          </div>
        </div>
        <br />
        <br />
      </section>
    );
  }
}
