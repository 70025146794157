import Formm from "./Formm";
import "./Footer.scss";
import { useEffect, useState } from "react";

function Footer() {
  const [date, setDate] = useState();

  const getYear = () => setDate(new Date().getFullYear());

  useEffect(() => {
    getYear();
  }, []);
  return (
    <>
      <footer
        className="dfine-footer-area footer"
        id="footer"
        style={{
          background: `linear-gradient(90deg, rgba(80,55,163,1) 0%, rgba(100,97,102,1) 0%, rgba(82,80,84,1) 3%, rgba(0,0,0,1) 100%, rgba(118,125,126,1) 100%, rgba(102,106,107,1) 100%, rgba(145,76,228,1) 100%, rgba(31,30,32,1) 100%, rgba(16,16,17,1) 100%, rgba(49,52,52,0.9472163865546218) 100%, rgba(120,37,185,1) 100%, rgba(66,67,69,1) 100%, rgba(90,90,93,1) 100%, rgba(117,119,120,1) 100%`,
          paddingTop: "47px",
        }}
      >
        <div className="container bb_white">
          <div className="mobile">
            <h4 className="title" id="mobileenqtext">
              ENQUIRY
            </h4>
            <div className="row">
              <Formm />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-nav">
                <h4 className="title">QUICK LINKS</h4>
                <ul>
                  <li>
                    <a href="/#banner">Home</a>
                  </li>
                  <li>
                    <a href="/#about">About</a>
                  </li>
                  <li>
                    <a href="/#focus">Focus</a>
                  </li>
                  <li>
                    <a href="#product">Products</a>
                  </li>
                  <li>
                    <a href="/#certification">Certification</a>
                  </li>
                  <li>
                    <a href="/#Event">News & Events</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="footer-nav"></div>
              <div className="desktop">
                <h4 className="title">ENQUIRY</h4>
                <div className="row">
                  <div className="col-md-10">
                    <Formm />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="footer-info">
                <h4 className="title">CONTACT US</h4>
                <ul>
                  <li>
                    <i className="fas fa-map-marker mt-2"></i>
                    <p>
                      Dfine Bio-Innovations Pvt Ltd,
                      <span className="break"></span>
                      428,GVR HEXA,4th floor,8th Main Rd, Koramangala 4th Block,
                      Koramangala,
                      <br />
                      Bengaluru,Karnataka-560034, Karnataka.{" "}
                    </p>
                  </li>
                  <li>
                    <i className="fas fa-phone mt-2"></i>
                    <p>
                      Contact Us –{" "}
                      <a
                        href="tel:080-4202-7704"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#ffffff" }}
                      >
                        080-4202-7704 /
                      </a>{" "}
                      <a
                        href="tel:08049911320"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#ffffff" }}
                      >
                        08049911320
                      </a>
                    </p>
                  </li>
                  <li>
                    <i className="fa fa-envelope mt-2"></i>
                    <p>
                      <a
                        href="mailto:dfinebio@gmail.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        dfinebio@gmail.com
                      </a>
                    </p>
                  </li>
                  <li>
                    <i className="fas fa-clock mt-2"></i>
                    <p>Mon-Fri: 10:00 – 19:00 Sat: 10:00 – 13:-00</p>
                  </li>
                </ul>
                <section id="lab_social_icon_footer">
                  <div className="container">
                    <div className="text-center center-block">
                      <a
                        href="https://www.facebook.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i
                          id="social-fb"
                          className="fa fa-facebook-square fa-3x social"
                        ></i>
                      </a>
                      <a
                        href="https://twitter.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i
                          id="social-tw"
                          className="fa fa-twitter-square fa-3x social"
                        ></i>
                      </a>
                      <a
                        href="https://www.linkedin.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i
                          id="social-ld"
                          className="fa fa-linkedin-square fa-3x social"
                        ></i>
                      </a>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>{" "}
        </div>
        <div className="mt-3 text-center">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="footer-info">
                  <p className="info">
                    {" "}
                    &#169; Dfine Bioinnovations (P) Ltd. {date}{" "}
                  </p>
                  <br />
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="footer-info">
                  <p className="infoo">
                    {" "}
                    Designed by:
                    <a
                      href="https://www.honeycombindia.net"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Honeycomb Creative Support
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
