import Focus1 from "../images/f1.png";
import Focus2 from "../images/f2.png";
import Focus3 from "../images/f3.png";
import Focus4 from "../images/f4.png";

import Focus7 from "../images/focus.png";

import bline from "../images/bline.png";
function Focus() {
  return (
    <>
      <section
        className="dfine-feature-area"
        id="focus"
        style={{
          background: `linear-gradient(90deg, rgba(80,55,163,1) 0%, rgba(150,65,217,1) 0%, rgba(90,22,143,1) 100%, rgba(118,125,126,1) 100%, rgba(102,106,107,1) 100%, rgba(145,76,228,1) 100%, rgba(147,72,224,1) 100%, rgba(16,16,17,1) 100%, rgba(49,52,52,0.9472163865546218) 100%, rgba(120,37,185,1) 100%, rgba(66,67,69,1) 100%, rgba(90,90,93,1) 100%, rgba(117,119,120,1) 100%`,
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
        <div className="container">
          <div className="row align-items-center mx-4">
            <div className="col-lg-4 ">
              <div className="mobile">
                <h2
                  className="focustextt mobile  text-white "
                  style={{ textalign: "center" }}
                >
                  Our Focus:
                </h2>
                <div className="row responsive_center mt-5">
                  <img src={Focus1} alt="" />
                </div>
              </div>
              <div className="masonry desktop">
                <div className="grid">
                  <img src={Focus1} alt="" />
                  <img src={bline} alt="" />
                </div>
                <div className="grid">
                  <img src={Focus4} alt="" />
                  <img src={bline} alt="" />
                </div>
                <div className="grid">
                  <img src={Focus3} alt="" />
                </div>

                <div className="grid">
                  <img src={Focus2} alt="" />
                  <img src={bline} alt="" />
                </div>
              
              </div>
            </div>
            {/* <div className="col-lg-1">
                  <img src={Fline} />
                  </div> */}
            <div className="col-lg-1"></div>
            <div className="col-lg-6">
              <div
                className="section-title section-title-2"
                id="focushead"
              >
                <div className="desktop">
                  <h2 className="titlee text-white">Our Focus:</h2>
               
                <p className="focpara">
                We are most relevant to IVD industry and with our deep roots in this industry we remain focussed on developing solutions for Entry to Mid-Size Segment Clinical Labs across all tiers. 
                </p>
                <p className="focpara">
                Further, we are keen on expanding our R&D and reach by indigenously developing state of the art medical technology devices in the fields of Hematology, Biochemistry and various POCT.
                </p>
                </div>
              </div>

              <div className="mobile">
         
              
                <p className="focpara">
                We are most relevant to IVD industry and with our deep roots in this industry we remain focussed on developing solutions for Entry to Mid-Size Segment Clinical Labs across all tiers. 
                </p>
                <p className="focpara">
                We are keen on expanding our R&D horizon in the fields of Haematology, Biochemistry and various POCT devices developing state-of-the-art technology medical devices, robust, stable and sustainable.
                </p>
               
              </div>

              {/* <div className="col">
                <button className="col btn btn-orange-moonn">Read More</button>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Focus;
