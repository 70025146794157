import Sep1 from "../images/se1.png";
import Sep2 from "../images/se2.png";
import Sep3 from "../images/se3.png";
import Sep4 from "../images/se4.png";
function Supported() {
  return (
    <>
      <section
        className="dfine-feature-area supported"
        id="vision"
        style={{
          background: `linear-gradient(90deg, rgba(68,68,70,1) 0%, rgba(16,16,17,1) 35%, rgba(86,90,91,0.9472163865546218) 67%, rgba(118,125,126,1) 94%, rgba(102,106,107,1) 97%, rgba(117,119,120,1)`,
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
        <div className="row justify-content-center">
        <h2 className="Evenhead text-white">Supported By</h2>
        </div>
        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-3 col-md-6">
              <div className="single-tema-item">
                <div className="thumb">
                  <img src={Sep1} alt="" />
                </div>
                <div className="content">
                  <h4 className="retitle text-white">SID</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-tema-item">
                <div className="thumb">
                  <img src={Sep2} alt="" />
                </div>
                <div className="content">
                  <h4 className="retitle text-white">DERBY</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-tema-item animated">
                <div className="thumb">
                  <img src={Sep3} alt="" />
                </div>
                <div className="content">
                  <h4 className="retitle text-white">NIDHI PRAYAS</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-tema-item animated">
                <div className="thumb">
                  <img src={Sep4} alt="" />
                </div>
                <div className="content">
                  <h4 className="retitle text-white">HONEYWELL</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </section>
    </>
  );
}

export default Supported;
