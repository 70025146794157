import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import emailjs from '@emailjs/browser';

function sendEmail(e) {
    e.preventDefault();
    alert("You have submitted sucessfully");
    emailjs
      .sendForm(
        "service_cz2fkup",
        "template_xlr5t68",
        e.target,
        "DrXVL5hmidBorjXo_"
      )
      .then(
        (result) => {
          window.location.reload();
        },
  
        (error) => {
          console.log(error.text);
        }
      );
  }


class Modelform extends React.Component {
    
    render() {
        return (
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    number:'',
                }}
                validationSchema={Yup.object().shape({
                    firstName: Yup.string()
                        .required('Name is required'),
                    lastName: Yup.string()
                        .required('Last Name is required'),
                    email: Yup.string()
                        .email('Email is invalid')
                        .required('Email is required'),
                    number: Yup.string()
                        .min(10, 'Number must be at least 10 characters')
                        .required('Mobile Number is required'),
                        
                    
                })}
                onSubmit={fields => {
                    alert('SUCCESS!! :-)\n\n' + JSON.stringify(fields, null, 4))
                }}
                render={({ errors, status, touched }) => (
                    <Form onSubmit={sendEmail}>
                        <div className="form-group">
                   
                            <Field  name="user_name" id="user_name" type="text" placeholder="Name*" className={'form-control modal_form_input' + (errors.firstName && touched.firstName ? ' is-invalid' : '')} required />
                            <ErrorMessage name="firstName" component="div" className="invalid-feedback" />
                        </div>
                       
                        <div className="form-group mt-3">
                     
                            <Field name="user_email"  type="email"  placeholder="Email*" className={'form-control modal_form_input' + (errors.email && touched.email ? ' is-invalid' : '')} required />
                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-group  mt-3">
                           
                            <Field name="mobile_number" placeholder="Mobile Number*"  type="text" className={'form-control modal_form_input' + (errors.number && touched.number ? ' is-invalid' : '')} required  pattern="[1-9]{1}[0-9]{9}"
                             title="Number should not be less than 10 or more"  />
                            <ErrorMessage name="number" component="div" className="invalid-feedback" />
                        </div>
                       
                        <div className="form-group text-center  mt-3">
                            <button type="submit" value="send"className="btn btn-primary mr-2 modal_submit_btn">Submit</button>
                        
                        </div>
                    </Form>
                )}
            />
        )
    }
}

export default Modelform;


