

function Product() {
  return (
    <>
      <section
        className="dfine-feature-area supported"
        id="product"
        style={{
          background: `linear-gradient(90deg, rgba(68,68,70,1) 0%, rgba(16,16,17,1) 35%, rgba(86,90,91,0.9472163865546218) 67%, rgba(118,125,126,1) 94%, rgba(102,106,107,1) 97%, rgba(117,119,120,1)`,
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
    
        <div className="container">
        <div className="row justify-content-center">
          <h2 className="producttext"> Products</h2>  
        </div>
        <br/>
        <br/>
        <h4 className="productpara"> Under Construction</h4>
        </div>
        {/* <div className="eventborderbottom"></div> */}
     
      </section>
    </>
  );
}

export default Product;
