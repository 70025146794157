import Logo from "../images/logo.svg";

import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "../Styles/main_new.css";
import { Link } from "react-router-dom";

function Header() {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <div className="header">
      <div className="container">
        <header>
          <div className="logo">
            <Link to="/">
              <img src={Logo} alt="logo" />
            </Link>
          </div>
          <div class="spfle">
            <button className="headerbutton px-2">
              <span className="expert">
                Talk to an Expert :
                <a href="tel:9845088171" target="_blank" rel="noreferrer">
                  {" "}
                  &nbsp;9845088171
                </a>
              </span>
            </button>
            <nav ref={navRef}>
              <Link to="/">Home</Link>
              <a href="/#about">About</a>
              <a href="/#focus">Focus</a>
              <a href="/#product">Products</a>
              <a href="/#certification">Certification</a>
              <a href="/#Event"> News & Events</a>

              <a href="/#footer">Contact</a>

              <button className="nav-btn nav-close-btn" onClick={showNavbar}>
                <FaTimes />
              </button>
            </nav>
          </div>
          <button className="nav-btn" onClick={showNavbar}>
            <FaBars />
          </button>
        </header>
      </div>
    </div>
  );
}

export default Header;
