import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Manasamodel() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary"  id="btcolor" onClick={handleShow}>
        Read More
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        <Modal.Title >About <span className='modeltitext'> Manas Kumar Panda </span>   </Modal.Title> 
        </Modal.Header>
        <Modal.Body>
          <p className='mx-4'>Manas is graduated in Business administration (BBA) with 12+ years experience in core accounts and administrative.</p><p> Well-versed with statutory compliances, IT regulations computation and filings, Import export intricacies, cost controls. 
            </p>
            <p className='mx-4'> Manas is excited to be part of success and growth of Dfine Bioinnovations pvt ltd</p>
            
           
            
         

        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default Manasamodel;