import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function News4Model() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button className='newsModelButton'  variant="" id="bttcolor" onClick={handleShow}>
                Read More
            </Button>
            <Modal className='modeltop'
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    {/* <Modal.Title >About <span className='modeltitext'>Modal </span>  </Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                <p className='modelpara'>
                Attended seminar at Derbi incubation centre
                    </p>

                </Modal.Body>
            </Modal>
        </>
    );
}

export default News4Model