import React, { useEffect, useRef } from "react";
import Video3 from "../Video/Video3.mp4";
import Desktop from "../images/ab2.png";
import Mobile from "../images/Mobile1.png";
function About() {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);
 

  return (
    <>

      <div
        className="dfine-feature-area about_section"
        id="about"
        style={{
          background: `linear-gradient(90deg, rgba(68,68,70,1) 0%, rgba(16,16,17,1) 35%, rgba(86,90,91,0.9472163865546218) 67%, rgba(118,125,126,1) 94%, rgba(102,106,107,1) 97%, rgba(117,119,120,1)`,
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
        <div className="container-fluid p-0">
          <div className="row align-items-center">
            
            <div className="col-lg-6">
              <div className="section-title section-title-2 about_text mx-4">
                <div className="abuthead desktop">
                  <h2 className="titlee text-white">About Us:</h2>
                </div>
               
                <p className="focpara">
                Dfine Bio-innovations Pvt Ltd is an aspiring MedTech Start-up focussed in the IVD Sector and are based out of Bangalore. Founded during December of 2020 with the belief that we could provide sustainable and cost-effective medical devices produced indigenously, we are fully focussed on its design and development.  
                
                </p>
                <p className="focpara">
                We foresee ourselves as a significant player in the highly evolving healthcare industry thus      being an aid to the healthcare fraternity who tirelessly work for the general health and wellness across populations.
                </p>
              </div>
              
              {/* <div className="col">
                <button className="col btn btn-orange-moonn">Read More</button>
              </div> */}
            </div>
            <div className="col-lg-6 abtmobile">
              <div className="section-title pl-100">
                <div className="abuthead mobile">
                <h2
                  className="focustextt mobile  text-white "
                  style={{ textalign: "center" }}
                >
                 About Us:
                </h2>
                  
                </div>
   
                <div className="row responsive_center">
                  <img className="desktop" src={Desktop} alt="cmd" id="abimage" />
                </div>
                <div className="row responsive_center">
                  <img className="mobile p-3" src={Mobile} alt="cmd" id="abimage" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
