import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Cmdmodel() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary"  id="bttcolor" onClick={handleShow}>
        Read More
      </Button>
     <div className='container'>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        <Modal.Title >Message from  <span className='modeltitext'>CMD </span>  </Modal.Title> 
        </Modal.Header>
        <Modal.Body>
          <p>        Dfine Bio-Innovations Pvt Ltd is an aspiring MedTech Start-up born with an intention to design, develop and manufacture credible 
                  and cost-effective Medical Devices. Echoing the call of nation towards self-reliance was one of the major reasons for us to form 
                  this startup.</p>
                  
                  
                   <p>We, moving in the right directions with sustained efforts and if supported by an strong regulatory system, 
                  Pro-manufacturing government policies and adequate infrastructure facilities/resources, I firmly believe that our country’s 
                  import dependency can be greatly downsized. Currently we are heavily import dependent for medical devices with almost 80% and above.
            </p>
            <p> Manufacturing is a big deal indeed. We as entrepreneurs face daunting challenges and dearth of facilities and resources, 
                  but we have started and let’s not STOP till we reach our goal of making our country self-reliant. To start with, DBI is 
                  focussing on low hanging fruits and gradually target the higher and difficult ones.
           </p>
           <p>    Overall, quiet interesting times!<br/>

All the best.</p>
         
       
           
            
         

        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer> */}
      </Modal>
      </div>
    </>
  );
}

export default Cmdmodel