import Banner from "./Banner";
// import Video from "./Video";
import Cmd from "./Cmd";
import Ceo from "./Ceo";
import About from "./About";
import Focus from "./Focus";
import Event from "./Event";
import Certification from "./Certification";
import Vision from "./Vision";
import Product from "./Product";
import Policy from "./Policy";
import Corevalue from "./Corevalue";
import Coreteam from "./Coreteam";
import Resourses from "./Resourses";
import Supported from "./Supported";
import Registered from "./Registered";
import AddressMap from "./AddressMap";
// import PhotoGallery from './PhotoGallery'
// import Form from "./Form";
import Scrollbutton from "./Scrollbutton";

function Home() {
  return (
    <>
      {/* <div className="sticky-social">
  <ul className="social">
    <li className="fb"><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
        <li className="twitter"><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
      <li className="insta"><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
    
    </ul>
  </div> */}

      <Banner />
      {/* <PhotoGallery /> */}
      <Cmd />
      <Ceo />

      <About />
      <Focus />
      <Vision />
      <Corevalue />
      <Event />
      <Policy />

      <Coreteam />
      <Certification />

      <Product />

      <Resourses />

      <Supported />
      <Registered />

      <AddressMap />
      {/* <Form />  */}
      <Scrollbutton />
    </>
  );
}

export default Home;
