import logo from "../images/ceo_bg.png";
import ceoBg from "../images/ceo.jpg";
import React, { useState } from "react";
import Ceomodel from "./Ceomodel";
function Ceo() {
 
 return (
    <>
    
      <section
        className="dfine-feature-area ceo_section"
        style={{
          backgroundImage: `url(${ceoBg})`,
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
            <h2
                  className="ceotextt mobile  text-white"
                  style={{ textalign: "center" }}
                >
                Message from CEO:
                </h2>
                <br/>
              <div className="section-title pl-100 mt-3">
            
                <div className="row responsive_center">
                  <img src={logo} alt=""  style={{width:'100%'}} />
                </div>
                
              </div>
            </div>
            <div className="col-lg-1">
              </div>
            <div className="col-lg-6">
              <div className="section-title section-title-2 mx-4">
                <div className="responsive_center desktop">
                <h2 className="titlee text-white">Message from CEO:</h2>
                </div>
               <p className="focpara">
                Medical Device in specific and the IVD industry in general is on upbeat. Several factors have and continue to contribute for its 
                stupendous success. IVD industry has responded positively towards the demand for high volume testing and addressed the needs for 
                quality and timely testing. Most important factor that contributed to the unprecedented growth is the pandemic itself.</p>
                <p className="focpara">
                 Other factor propelling it further are growing health awareness among population, geriatric population, disposable incomes, 
                 insurance coverage, government thrust and impetus on health. The Availability of automated systems those are efficient, 
                 accurate also helps in the growth drive. </p>
                 <p className="focpara">
                 The growing health awareness among general population, high disposable incomes, increasing insurance coverage, ageing strata have all further propelled the growth of IVD industry.
                 
                 <span></span> <Ceomodel/></p>

        
           
              </div>
              <br />
              <br />
              {/* <div className="col">
		    <button className="col btn btn-orange-moon">Read More</button>
		</div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Ceo;
