function Vision() {
  return (
    <div>
      <section
        className="dfine-feature-area"
        id="vision"
        style={{
          background: `linear-gradient(90deg, rgba(68,68,70,1) 0%, rgba(16,16,17,1) 35%, rgba(86,90,91,0.9472163865546218) 67%, rgba(118,125,126,1) 94%, rgba(102,106,107,1) 97%, rgba(117,119,120,1)`,
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
       <div className="container">
          <div className="row justify-content-between position-relative">
            <div className="col-lg-5">
              <div className="section-title section-title-2 mx-4">
                <h2 className="text-white">Vision Statement:</h2>
                <p className="focpara">
                To be an India's Formidable Medical Device Manufacturing Company focussed on small labs across all tiers with its products having a Global Appeal 


                </p>
              </div>
            </div>

            <div className="col-lg-5 border-r">
              <div className="section-title mx-4">
                <h2 className="text-white">Mission Statement:</h2>
                <p className="focpara">
                With the backing of ambitious and visionary team, emphasis on quality & reliability, Dfine Bio-innovations Pvt Ltd strives to achieve happiness, success and satisfaction dealing with customers, distributors and stakeholders alike!

                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Vision;
