import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Saimodel() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary"  id="btcolor" onClick={handleShow}>
        Read More
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        <Modal.Title >About <span className='modeltitext'>Medicherla Sai Prathima </span>   </Modal.Title> 
        </Modal.Header>
        <Modal.Body>
          <p className='mx-4'>Prathima has a Masters in Pharmacy and a fresher. Well worsted with QC and Regulatory protocols Prathima is a curious yet confident in fulfilling this critical aspect of a Start-up.  </p>
          <p className='mx-4'>Keen Learner,Adaptable. Prathima is quiet excited to be a part of DBI Startup Regulatory and IP Analyst. She can put foot forward to deliver responsibilities for DBI.</p>
       
           
            
         

        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default Saimodel;