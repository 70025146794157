
import './App.css';
import Home from './Components/Home';
import Header from './Components/Header';
import Footer from './Components/Footer';

// import Teams from './Components/Teams';
import { BrowserRouter, Route, Routes } from 'react-router-dom';


function App() {
  return (
    <div>



      <Header />

      <Routes>
        <Route exact path="/" element={<Home />} />



        {/* <Route exact path="/teams" element={  <Teams /> } /> */}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
