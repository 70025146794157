import Ourteamslider from "./Ourteamslider";

function Coreteam() {
  return (
    <div>
      <Ourteamslider />
    </div>
  );
}

export default Coreteam;
