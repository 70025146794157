import RegisteredSlider from "./RegisteredSlider";

function Registered() {
  return (
    <>
      <section
        className="dfine-feature-area"
        id="vision"
        style={{
          background: `linear-gradient(90deg, rgba(80,55,163,1) 0%, rgba(150,65,217,1) 0%, rgba(90,22,143,1) 100%, rgba(118,125,126,1) 100%, rgba(102,106,107,1) 100%, rgba(145,76,228,1) 100%, rgba(147,72,224,1) 100%, rgba(16,16,17,1) 100%, rgba(49,52,52,0.9472163865546218) 100%, rgba(120,37,185,1) 100%, rgba(66,67,69,1) 100%, rgba(90,90,93,1) 100%, rgba(117,119,120,1) 100%`,
        }}
      >
        {/* <h2 className="Evenhead mb-5">
          Registered at
       
        </h2> */}

        <RegisteredSlider />
      </section>
    </>
  );
}

export default Registered;
