import Res1 from "../images/r1.png";
import Res2 from "../images/r2.png";
import Res3 from "../images/r3.png";
import Res4 from "../images/r4.png";

function Resourses() {
  return (
    <>
      <section
        className="dfine-feature-area resources"
        id="vision"
        style={{
          background: `linear-gradient(90deg, rgba(80,55,163,1) 0%, rgba(150,65,217,1) 0%, rgba(90,22,143,1) 100%, rgba(118,125,126,1) 100%, rgba(102,106,107,1) 100%, rgba(145,76,228,1) 100%, rgba(147,72,224,1) 100%, rgba(16,16,17,1) 100%, rgba(49,52,52,0.9472163865546218) 100%, rgba(120,37,185,1) 100%, rgba(66,67,69,1) 100%, rgba(90,90,93,1) 100%, rgba(117,119,120,1) 100%`,
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
       <div className="row justify-content-center">
         <h2 className="Evenhead text-white">Resourses</h2>
        </div>
        <div className="container">
          <div className="row justify-content-center mt-5 text-center">
            <div className="col-lg-3 col-md-6">
              <div className="single-tema-item">
                <a
                  href="http://admi-india.org/"
                  title="dfine"
                  alt=""
                  target="_blank"
                  rel="noopener"
                >
                  <div className="thumb">
                    <img src={Res1} alt="" />
                  </div>
                  <div className="content">
                    <h4 className="retitle text-white">ADMI</h4>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-tema-item">
                <a
                  href="https://cdsco.gov.in/opencms/opencms/en/Home/"
                  rel="noopener"
                  title="dfine"
                  alt=""
                  target="_blank"
                >
                  <div className="thumb">
                    <img src={Res2} alt="" />
                  </div>
                  <div className="content">
                    <h4 className="retitle text-white">CDSCO</h4>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div
                className="single-tema-item animated"
                title="dfine"
                rel="noopener"
                alt=""
                target="_blank"
              >
                <a href="https://www.aimedindia.com" target="_blank">
                  <div className="thumb">
                    <img src={Res3} alt="" />
                  </div>
                  <div className="content">
                    <h4 className="retitle text-white">AIMED</h4>
                  </div>
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div
                className="single-tema-item animated"
                title="dfine"
                rel="noopener"
                alt=""
                target="_blank"
              >
                <a href="https://practical-haemostasis.com" target="_blank">
                  <div className="thumb">
                    <img src={Res4} alt="" />
                  </div>
                  <div className="content">
                    <h4 className="retitle text-white">HEMOSTASIS</h4>
                  </div>
                </a>
              </div>
            </div>
      
          </div>
        </div>
      </section>
    </>
  );
}

export default Resourses;
