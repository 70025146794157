import 'photoswipe/dist/photoswipe.css'
import certi from "../images/certifcate.png";
import { Gallery, Item } from 'react-photoswipe-gallery'

const LightboxGallery = () => (
  <div style={{textAlign:"center"}}>  
    <Gallery>
    <Item
      src={certi}
      thumbnail={certi}
      width="330"
      height="455"
      alt="certificate"
    >
      {({ ref, open }) => (
        <img  ref={ref} onClick={open} src={certi}   alt="certificate" />
      )}
    </Item>
    {/* <Item
      original="https://placekitten.com/1024/768?image=2"
      thumbnail="https://placekitten.com/80/60?image=2"
      width="1024"
      height="768"
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src="https://placekitten.com/80/60?image=2" />
      )}
    </Item> */}
  </Gallery>
  </div>

)

export default LightboxGallery;