import React, { useEffect, useRef } from "react";
import Video3 from "../Video/Video3.mp4";

export default function Banner() {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <>
      <div className="vidddo"  id="banner">
        <div className="videobg">
        <video className=""
          width="100%"
          preload="none"
          poster=""
          playsInline=""
          muted="muted"
          loop={true}
          autoPlay={true}
          style={{height:'39rem'}}
        >
          <source src={Video3} type="video/mp4" />
        </video>
        {/* <video className="mobile"
          width="100%"
          preload="none"
          poster=""
          playsInline=""
          muted="muted"
          loop={true}
          autoPlay={true}
        >
          <source src={Video4} type="video/mp4" />
        </video> */}
        </div>
        
      </div>
      
      {/* <div className="bannertext">
        <h1>Test Video</h1>
      </div> */}
      </>
  );
}
