import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Teammmodel() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" id="btcolor" onClick={handleShow}>
        Read More
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        <Modal.Title >About <span className='modeltitext'>Afser Shariff</span>  </Modal.Title> 
        </Modal.Header>
        <Modal.Body>
          <p className='mx-4'>Afser Shariff a Serial Entrepreneur with 20+ years exposure to Medical Device, Life Sciences, IVD Industry  with multiple skill-sets and well versed
            
             in regulatory requirements, good communication skills, established</p>
             
             <p className='mx-4'> customer connect and management abilities with Life-Science as academic background is devoted full time for success of Dfine Bio-innovations Pvt Ltd - An aspiring Med-tech  startup HQ in bangalore focused in design and indigenous development of Sustainable, Cost-Effective Medical devices.
</p>
        
       
           
            
         

        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default Teammmodel