import React, { Component } from "react";
import Slider from "react-slick";
import res1 from "../images/res1.png";
import res2 from "../images/res2.png";
import res3 from "../images/res3.png";
import res4 from "../images/res4.png";
import res5 from "../images/res5.png";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "" }}
      onClick={onClick}
    />
  );
}

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="dfine-feature-areaaa responsive_padding">
        
        <div className="container">
          <div className="row justify-content-center">
            <h2 className="Evenhead text-white">Registered at</h2>
          </div>
          <div className="row mt-5">
            <Slider {...settings}>
              <div>
                <img src={res1} alt="res1" />
              </div>
              <div>
                <img src={res2} alt="res2" />
              </div>
              <div>
                <img src={res3} alt="res2" />
              </div>
              <div>
                <img src={res4} alt="res4" />
              </div>
              <div>
                <img src={res5} alt="res2" />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}
