import 'photoswipe/dist/photoswipe.css'
import NewsImg1 from '../images/news_img1.png'
import { Gallery, Item } from 'react-photoswipe-gallery'
import event1 from '../images/event1.jpg'
import PhotoGalleryPerson1 from '../images/photo_gallery_person1.jpeg'

const PhotoGallery = () => (
    <Gallery>
        <Item
            src={event1}
            thumbnail={event1}
            width="1024"
            height="768"
        >
            {({ ref, open }) => (
                <img ref={ref} onClick={open} src={event1} style={{ width: "100%" }} />

            )}
        </Item>
        {/* <Item
            original="https://placekitten.com/1024/768?image=2"
            thumbnail="https://placekitten.com/80/60?image=2"
            width="1024"
            height="768"
        >
            {({ ref, open }) => (
                <img ref={ref} onClick={open} src="https://placekitten.com/80/60?image=2" />
            )}
        </Item> */}
    </Gallery>
)

export default PhotoGallery