import React from "react";
const AddressMap = () => {
  return (
    <div className="App">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31108.729786489333!2d77.60417387152167!3d12.933973966346667!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1541167083db%3A0xec95dcea70873f1c!2sDfine%20Bio-Innovations%20Pvt.Ltd.!5e0!3m2!1sen!2sin!4v1672051406792!5m2!1sen!2sin"
       
        width="100%"
        title="map"
        height="450"
        style={{ border: "0" }}
        loading="lazy"
      ></iframe>

    </div>
  );
};

export default AddressMap;
