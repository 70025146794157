import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Shuchimodel() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" id="btcolor" onClick={handleShow}>
        Read More
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        <Modal.Title >About <span className='modeltitext'>Shuchi Kumari</span>    </Modal.Title> 
        </Modal.Header>
        <Modal.Body>
          <p className='mx-4'> Shuchi is a graduate in Medical Lab Technology and has expertise hands-on working with various sophisticated clinical laboratory devices and its operations, laboratory test methods, report validations and QC concepts.</p>
          <p className='mx-4'> Shuchi is excited to be a part of DBI startup and finds the new role quiet challenging yet interesting as she is able to apply her Laboratory experience in and more profound space as a QC and Regulatory Analyst  at DBI at its R&D and product development lab.</p>
           
       
           
            
         

        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default Shuchimodel;