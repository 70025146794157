import React, { Component } from "react";
import Slider from "react-slick";
import NewsImg1 from '../images/news_img1.png'
import './news-events-slider.scss'
import News1Model from "./NewsModels/News1Model";
import News2Model from "./NewsModels/News2Model";
import News3Model from "./NewsModels/News3Model";
import News4Model from "./NewsModels/News4Model";
import PhotoGallery from "./PhotoGallery";
import PhotoGallery2 from "./PhotoGallery2";
import PhotoGallery1 from "./PhotoGallery1";
import PhotoGallery3 from "./PhotoGallery3";
import PhotoGallery4 from "./PhotoGallery4";

import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";


export default class NewsEventsSlider extends Component {

    render() {

        var settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                    }
                }
            ]
        };
        return (
            <div className="newsEventsContainer mt-3">
                <Slider {...settings} >

                    <div className="card">

                        {/* <img className="card-img-top newsImg" src={NewsImg1} alt="news" /> */}
                        <PhotoGallery />
                        <div className="card-body">
                            <p className="card-title text-center text-white">Pitch presentation at derbi incubation centre</p>
                            <div className="items-center text-center">
                                <News1Model />
                            </div>
                        </div>

                    </div>
                    <div className="card">
                        {/* <img className="card-img-top newsImg" src={NewsImg1} alt="news" /> */}
                        <PhotoGallery2 />
                        <div className="card-body">
                            <p className="card-title text-center text-white">Team attended session for Karnataka startup</p>
                            <div className="items-center text-center">
                                <News3Model />
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        {/* <img className="card-img-top newsImg" src={NewsImg1} alt="news" /> */}
                        <PhotoGallery3 />
                        <div className="card-body">
                            <p className="card-title text-center text-white"> Attended seminar at Derbi incubation centre</p>
                            <div className="items-center text-center">
                                <News4Model />
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        {/* <img className="card-img-top newsImg" src={NewsImg1} alt="news" /> */}
                        <PhotoGallery4 />
                        <div className="card-body">
                            <p className="card-title text-center text-white">ISO 13485 and CE Awareness Training Programme</p>
                            <div className="items-center text-center">

                                <News2Model />
                            </div>
                        </div>
                    </div>

                    {/* <div className="card">
                      
                        <PhotoGallery />
                        <div className="card-body">
                            <p className="card-title text-center text-white">Engineers and R&D Team</p>
                            <div className="items-center text-center">
                                <News3Model />
                            </div>
                        </div>
                    </div> */}


{/*                    
                    <div className="card">
                 
                        <PhotoGallery />
                        <div className="card-body">
                            <p className="card-title text-center text-white">Derbi</p>
                            <div className="items-end text-end">
                                <News1Model />
                            </div>
                        </div>
                    </div> */}
                </Slider>
            </div>
        );
    }
}