import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Lokeshmodel() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary"  id="btcolor" onClick={handleShow}>
        Read More
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        <Modal.Title >About <span className='modeltitext'>Lokesh Murthy K M </span>  </Modal.Title> 
        </Modal.Header>
        <Modal.Body>
          <p className='mx-4'>Lokesh Murthy K M has an Engineering background and his USP is product development, critical thinking, sustainable and cost-effective design concepts. 
            </p>
            <p className='mx-4'>With 14+ years exposure to Medical Device, Life Sciences and IVD Industry, Lokesh is on a mission to innovate and develop robust yet cost-effective medical devices. 
               With deep understanding of market dynamics, competition, design knowledge.
           </p>
         
       
           
            
         

        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default Lokeshmodel