import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Prathapmodel() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary"  id="btcolor" onClick={handleShow}>
        Read More
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        <Modal.Title >About <span className='modeltitext'>Prathap</span>     </Modal.Title> 
        </Modal.Header>
        <Modal.Body>
          <p className='mx-4'>Prathap is an Engineer by academics with 10+ years exposure as an astute marketing person in the domains of Medical Device, Life Sciences and IVD Industry, </p>
           <p className='mx-4'>  Prathap is an accomplished Sales & Marketing person with excellent track record in his previous stints as Marketing Manager and has developed formidable connections with distributors and govt sector. Well versed in tenders and big institutional business.
</p>

            
       
           
            
         

        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default Prathapmodel