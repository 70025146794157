
import Qua1 from "../images/tgr1.png";
function Policy() {
  return (
    <>
      <section
        className="dfine-feature-area"
        style={{
          background: `linear-gradient(90deg, rgba(80,55,163,1) 0%, rgba(150,65,217,1) 0%, rgba(90,22,143,1) 100%, rgba(118,125,126,1) 100%, rgba(102,106,107,1) 100%, rgba(145,76,228,1) 100%, rgba(147,72,224,1) 100%, rgba(16,16,17,1) 100%, rgba(49,52,52,0.9472163865546218) 100%, rgba(120,37,185,1) 100%, rgba(66,67,69,1) 100%, rgba(90,90,93,1) 100%, rgba(117,119,120,1) 100%`,
          backgroundSize: "cover",
          overflow: "hidden",
          paddingTop: "47px",
        }}
      >
        <br />
        <div className="container">
          <div className="row align-items-center mx-4">
          <div className="col-lg-6 text-center mobile">
          <h2 className="text-white">Quality Policy:</h2>
              <div className="section-title pl-100">
                <img src={Qua1} alt="policy" style={{width:'100%'}} />
              </div>
              <p className="focpara mt-5">
                Dfine Bio-Innovations Pvt Ltd is committed to maintain high standards in its design and manufacture of medical devices yet keeping it affordable. Our products shall address specific needs of customers and continually
                 work on their pain points Dfine Bioinnovation shall comply with environment and statutory compliances develop employee skills through effective regular trainings, choose the right vendors / service providers in tandem with our quality requirements.


                </p>
            </div>
            <div className="col-lg-6 desktop">
              <div className="section-title section-title-2">
                <h2 className="text-white">Quality Policy:</h2>
                
                <p className="focpara">
                Dfine Bio-Innovations Pvt Ltd is committed to maintain high standards in its design and manufacture of medical devices yet keeping it affordable. Our products shall address specific needs of customers and continually
                 work on their pain points Dfine Bioinnovation shall comply with environment and statutory compliances develop employee skills through effective regular trainings, choose the right vendors / service providers in tandem with our quality requirements.


                </p>
             
                <br />
                <br />
              </div>
            </div>

            <div className="col-lg-6 text-center desktop">
              <div className="section-title pl-100">
                <img src={Qua1} alt="policy" style={{width:'100%'}} />
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </section>
    </>
  );
}

export default Policy;
