import cmdBg from "../images/Asset3.jpg";
import logo from "../images/IMGfsdvf1.png";
import React, { useState } from "react";
import Cmdmodel from "./Cmdmodel";
function About() {

  return (
    <>
      <section
        className="dfine-feature-area cmd_section"
        style={{
          backgroundImage: `url(${cmdBg})`,
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
        <div>
   
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="section-title section-title-2 mx-4">
                  <div className="responsive_center desktop">
                    <h2 className="titlee text-white">Message from CMD:</h2>
                  </div>
                <p className="focpara"> For centuries,  the developments of science and technology have irrevocably changed human life. And in the recent decade the tech industry 
                  has pushed this transformation to new heights. We at Dfine Bio-Innovations understand meeting our customer's requirement is  essential to the success of our organization. 
 </p>
                 
                  <p className="focpara">We are now in an ever-changing market environment. In such situation, we firmly believe that “trust” is the key element 
                  in persuading customers to choose our products.</p> 
          

                 

              
                  <Cmdmodel />

     
                  
                </div>
                <br />
                <br />
                {/* <div className="col">
		    <button className="col btn btn-orange-moon">Read More</button>
		</div> */}
              </div>
              
              <div className="col-lg-6 cmdmobile">
                <div className="section-title pl-100">
                <h2
                  className="cmdtextt mobile  text-white "
                  style={{ textalign: "" }}
                >
                 Message from CMD:
                </h2>
                <br/>
                  <img src={logo} alt="cmdd" style={{width:'100%'}} />
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
