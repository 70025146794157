
import Bookmark from "../images/Bookmark.png";
import certi from "../images/certifcate.png";
import React from 'react'
import LightboxGallery from "./LightboxGallery";


function Certification() {
  return (
    <>
      <section className="dfine-feature-area" id="certification">
        <div className="container">
        <div className="row justify-content-center">
          <h2 className="Certihead">Certification</h2>  
        </div>
        </div>
        {/* <div className="eventborderbottom"></div> */}
        <div className="container mt-3">
          {/* <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="section-title pl-100">
                <p className="cpara text-center" style={{ color: "#716f69" }}>
                  In publishing and graphic design, Lorem ipsum is a placeholder
                  text commonly used to demonstrate the visual form of a
                  document or a typeface without relying on meaningful content.
                  Lorem ipsum may be used as a placeholder before final copy is
                  available.
                </p>
                <br />
              </div>
            </div>
          </div> */}
          <div className="imaggcar">
          {/* <img src={certi} alt="team2" /> */}
          </div>
          <LightboxGallery/>
        
          {/* <div className="row align-items-center"> */}
            {/* <div className="col-lg-12 mb-3">
              <div className="certification_block">
                <div className="card">
                <img src={certi} alt="team2" />

                
                </div>
              </div>
            </div> */}
            {/* <div className="col-lg-4 mb-3">
              <div className="certification_block">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ backgroundImage: `url(${background1})` }}
                  >
                    <div className="card_header_text">
                      <h6 className="certitle text-white">Certification 2</h6>
                      <p className="cparag">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </p>
                    </div>
                  </div>

                
                </div>
              </div>
            </div> */}
            {/* <div className="col-lg-4 mb-3">
              <div className="certification_block">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ backgroundImage: `url(${background2})` }}
                  >
                    <div className="card_header_text">
                      <h6 className="certitle text-white">Certification 3</h6>
                      <p className="cparag">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div> */}
          {/* </div> */}
        </div>
      </section>
    </>
  );
}

export default Certification;
